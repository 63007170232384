.certifications {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.certifications:hover {
  transform: translateY(-7px);
}

.certifications__description {
  margin-top: 1em;
}

.badge--icon {
  /* width: 80%; */
  width: 50%;
  height: fit-content(10em);
}

.certifications__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.certifications__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.certifications .link--icon {
  margin-left: 0.5em;
}

